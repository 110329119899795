import React from 'react'

const CommingSoon = () => {
    return (
        <div style={{ height: 400, width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '40px', fontWeight: '800' }}>
            <h1 >Content will comming soon...</h1>
        </div>
    )
}

export default CommingSoon
