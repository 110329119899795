
const { makeStyles } = require("@mui/styles");

export const useStyles = makeStyles(() => ({

    dashboardParent:{
        paddingTop: '50px'
    },


    
}));
