import { makeStyles } from "@mui/styles";
import { theme } from "../../theme";

export const useStyles = makeStyles(() => ({
    
    
    productsSliderStyle:{
        
    },
  


    // ====================================
    // ====================================
    [theme.breakpoints.down('md')]: {
       
    },
    [theme.breakpoints.down('sm')]: {
       

    },
    [theme.breakpoints.down('xs')]: {
       
    },
}));